import { TOGGLE_BASE_VARIANTS, TOGGLE_SLIDER_VARIANTS } from '../../../constants'
import { ToggleBaseProps } from '../../../types/atoms'
import { color, variant } from 'styled-system'
import { theme } from '../../../theme'
import styled from 'styled-components'

export const ToggleBase = styled.label<ToggleBaseProps>`
  display: inline-block;
  position: relative;
  ${variant({
    prop: 'sizeVariant',
    variants: { ...TOGGLE_BASE_VARIANTS }
  })}

  input {
    opacity: 0;
  }

  .slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100px;
    background-color: ${theme.colors.secondary.backgroundDark};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: 0.4s;
    ${color}
  }

  .slider:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 50%;
    border-radius: 100px;
    background-color: ${theme.colors.primary.white};
    transform: translate(0, -50%);
    transition: 0.4s;
    ${variant({
      prop: 'sizeVariant',
      variants: { ...TOGGLE_SLIDER_VARIANTS }
    })}
  }

  input:checked + .slider {
    background-color: ${theme.colors.primary.flowBlue} !important;
    ${color}
  }

  input:checked + .slider:before {
    left: calc(100% - 5px);
    transform: translate(-100%, -50%);
  }
`
